import React, { FunctionComponent, createContext, useState } from 'react';
import ConfirmModal from './ConfirmModal';
import { ButtonColors } from '../Button';

export interface ConfirmModalContextType {
    help?: string;
    title: string;
    prompt: string;
    button?: string;
    color?: ButtonColors;
    isOpen: boolean;
    proceed?: () => void;
    cancel?: () => void;
}

export const ConfirmModalContext = createContext<
    [ConfirmModalContextType, React.Dispatch<React.SetStateAction<ConfirmModalContextType>>]
>([
    {
        title: '',
        prompt: '',
        button: undefined,
        color: undefined,
        isOpen: false,
        proceed: () => {},
        cancel: () => {},
    },
    () => {},
]);

const ConfirmModalContextProvider: FunctionComponent<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [confirm, setConfirm] = useState<ConfirmModalContextType>({
        title: '',
        prompt: '',
        button: undefined,
        color: undefined,
        isOpen: false,
        proceed: () => {},
        cancel: () => {},
    });

    return (
        <ConfirmModalContext.Provider value={[confirm, setConfirm]}>
            {children}
            <ConfirmModal />
        </ConfirmModalContext.Provider>
    );
};

export default ConfirmModalContextProvider;
