import { useContext, useEffect, useState } from 'react';
import { ConfirmModalContext } from './ConfirmModalContextProvider';
import { ButtonColors } from '../Button';

const useConfirmModal = () => {
    const [confirm, setConfirm] = useContext(ConfirmModalContext);
    const [needsCleanup, setNeedsCleanup] = useState(false);

    const isConfirmed = (
        prompt: string,
        title: string,
        button?: string,
        color?: ButtonColors,
        help?: string,
    ) => {
        const promise = new Promise<void>((resolve, reject) => {
            setConfirm({
                prompt,
                title,
                button,
                color,
                help,
                isOpen: true,
                proceed: resolve,
                cancel: reject,
            });
            setNeedsCleanup(true);
        });

        const reset = () => {
            setConfirm({
                prompt: '',
                title: '',
                button: undefined,
                color: undefined,
                help: undefined,
                proceed: undefined,
                cancel: undefined,
                isOpen: false,
            });
            setNeedsCleanup(false);
        };

        return promise.then(
            () => {
                reset();
                return true;
            },
            () => {
                reset();
                return false;
            },
        );
    };

    // Call cancel in a cleanup func to avoid dangling confirm dialog
    useEffect(() => {
        return () => {
            if (confirm.cancel && needsCleanup) {
                confirm.cancel();
            }
        };
    }, [confirm, needsCleanup]);

    return {
        ...confirm,
        isConfirmed,
    };
};

export default useConfirmModal;
