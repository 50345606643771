/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const CREDENTIALS = process.env.CREDENTIALS === 'true';
export const {
    NODE_ENV,
    PUBLIC_URL,
    REACT_APP_PUBLIC_URL,
    REACT_APP_VERSION,
    REACT_APP_BUILD,
    REACT_APP_NAME,
    REACT_APP_DATA_SERVICE_URL,
    REACT_APP_AUTH_SERVICE_REALM,
    REACT_APP_AUTH_SERVICE_CLIENT,
    REACT_APP_AUTH_SERVICE_SERVER_URL,
} = process.env;

export const AMPLIFY_CONFIG = {
    region: 'eu-central-1',
    auth: {
        userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USERPOOLID!,
        userPoolWebClientId: process.env.REACT_APP_AMPLIFY_AUTH_USERPOOLWEBCLIENTID!,
        domain: process.env.REACT_APP_AMPLIFY_AUTH_DOMAIN!,
        redirectUrl:
            process.env.PUBLIC_URL && process.env.PUBLIC_URL?.length > 0
                ? process.env.PUBLIC_URL
                : 'http://localhost:8080',
    },
};
