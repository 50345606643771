import { setTasks, clearTasks } from '../actions/tasks.action';

import { createReducer } from '@reduxjs/toolkit';

export const TASKS_REDUCER_KEY = 'tasks';

export const tasksReducer = createReducer(0, (builder) => {
    builder.addCase(clearTasks, () => 0);
    builder.addCase(setTasks, (state, action) => action.payload);
});
