import useConfirm from './useConfirmModal';
import { Dialog } from '@headlessui/react';
import Button from '../Button';
import classNames from 'classnames';

const ConfirmModal = () => {
    const { prompt, title, button, color, help, isOpen = false, proceed, cancel } = useConfirm();

    const usedCancel = cancel ?? (() => {});

    return (
        <Dialog open={isOpen} onClose={() => {}} className="relative z-100">
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
            <div className="fixed inset-0 flex items-center justify-items-stretch p-4">
                <Dialog.Panel className="mx-auto rounded-md bg-white flex-1 max-w-xl px-7 py-8 transition-all duration-300">
                    <div className="space-y-5">
                        <Dialog.Title className="pb-3 mx-2 border-b border-gray-200 flex justify-between items-center space-x-5">
                            <div className="flex-1 font-semibold text-2xl">
                                <span className="text-gray-800">{title}</span>
                            </div>
                        </Dialog.Title>

                        <div className="pb-8 px-2 space-y-8">
                            <p className="text-gray-400 text-lg font-normal">{prompt}</p>
                        </div>

                        <div
                            className={classNames('px-2 flex', {
                                'justify-between items-end': help,
                                'justify-start items-end': !help,
                            })}
                        >
                            {help && (
                                <a
                                    href={help}
                                    target="blank"
                                    className="cursor-pointer text-blue-400 font-semibold text-sm"
                                >
                                    Hilfe
                                </a>
                            )}
                            <div className="space-x-2 flex justify-between items-start">
                                <Button
                                    as="button"
                                    color="gray"
                                    onClick={() => {
                                        usedCancel();
                                    }}
                                >
                                    Abbrechen
                                </Button>
                                <Button
                                    as="button"
                                    color={color ?? 'blue'}
                                    onClick={() => {
                                        proceed && proceed();
                                    }}
                                >
                                    {button ?? 'Bestätigen'}
                                </Button>
                            </div>
                        </div>
                    </div>
                </Dialog.Panel>
            </div>
        </Dialog>
    );
};

export default ConfirmModal;
