import React, { FunctionComponent } from 'react';
import Lottie from 'lottie-react';
import groovyWalkAnimation from './loading.json';

interface LoadingProps {
    className?: string;
}

const Loading: FunctionComponent<LoadingProps> = ({ className }) => {
    return <Lottie className={className} animationData={groovyWalkAnimation} loop={true} />;
};

export default Loading;
