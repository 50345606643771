import './App.scss';
import '../scss/style.scss';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import Loader from '../components/Loader';
import { Suspense, useEffect } from 'react';
import routes from '../routes';
import { Auth } from 'aws-amplify';

const loading = (
    <div className="flex h-screen">
        <div className="m-auto">
            <Loader />
        </div>
    </div>
);

const router = createBrowserRouter(routes);

function App() {
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(() => {})
            .catch((err) => {
                Auth.federatedSignIn();
            });
    }, []);

    return (
        <>
            <Suspense fallback={loading} />
            <RouterProvider router={router} />
        </>
    );
}

export default App;
