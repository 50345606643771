import { setApps, clearApps } from '../actions/apps.action';

import { createReducer } from '@reduxjs/toolkit';

export const APPS_REDUCER_KEY = 'apps';

export const appsReducer = createReducer(0, (builder) => {
    builder.addCase(clearApps, () => 0);
    builder.addCase(setApps, (state, action) => action.payload);
});
