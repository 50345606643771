import { getToken } from '../../services/auth.service';
import { REACT_APP_DATA_SERVICE_URL } from '../../config';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

// Create our baseQuery instance
export const baseQuery = fetchBaseQuery({
    baseUrl: REACT_APP_DATA_SERVICE_URL,
    prepareHeaders: async (headers) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = await getToken();
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});
