import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
// import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'de',
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        ns: ['common', 'catalog'],
        defaultNS: 'common',
        resources: {
            en: {
                namespace1: {
                    key: 'hello from namespace 1',
                },
                catalog: {
                    Preview: 'Preview',
                },
            },
            de: {
                common: {
                    key: 'hallo von namespace 1',
                },
                error: {
                    'Not Found': 'Kein Eintrag',
                    FETCH_ERROR: 'Verbindungsfehler',
                    FETCH_ERROR_DESC:
                        'Es ist ein Fehler aufgetreten beim Abrufen der Daten. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut oder wenden Sie sich an den technischen Support.',
                },
                catalog: {
                    Preview: 'Vorschau',
                },
            },
        },
    });

export default i18n;
