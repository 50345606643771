import { closeSidebar, openSidebar, toggleSidebar } from '../actions/settings.action';

import { createReducer } from '@reduxjs/toolkit';

export const SIDEBAR_REDUCER_KEY = 'sidebar';

export const sidebarReducer = createReducer(false, (builder) => {
    builder.addCase(openSidebar, () => true);
    builder.addCase(closeSidebar, () => false);
    builder.addCase(toggleSidebar, (state) => !state);
});
