import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';

export function getLoggedInUserID() {
    return Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
        // return user.username;
    });
}

export function getLoggedInUsername() {
    return Auth.currentAuthenticatedUser().then(async (user: CognitoUser) => {
        return user.getUsername();
    });
}

export const getToken = () => {
    return Auth.currentSession().then((res) => {
        let accessToken = res.getIdToken();
        let jwt = accessToken.getJwtToken();
        return jwt;
    });
};
