import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistStore } from 'redux-persist';
import { SIDEBAR_REDUCER_KEY, sidebarReducer } from '../reducers/sidebar';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { RESET_STATE_ACTION_TYPE } from '../actions/resetState';
import { Reducer } from 'redux';
import { unauthenticatedMiddleware } from '../middleware/unauthenticatedMiddleware';
import { tasksReducer, TASKS_REDUCER_KEY } from '../reducers/tasks';
import { appsReducer } from '../reducers/apps';
import { apiSlice } from '../slices/api.slices';

const reducers = {
    [SIDEBAR_REDUCER_KEY]: sidebarReducer,
    [TASKS_REDUCER_KEY]: tasksReducer,
    [TASKS_REDUCER_KEY]: appsReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
    if (action.type === RESET_STATE_ACTION_TYPE) {
        state = {} as RootState;
    }

    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        })
            .concat([unauthenticatedMiddleware])
            .concat([apiSlice.middleware]),
    devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
export const useTypedDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
