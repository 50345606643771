import classNames from 'classnames';
import React, {
    ButtonHTMLAttributes,
    cloneElement,
    DetailedHTMLProps,
    ElementType,
    FunctionComponent,
    JSXElementConstructor,
    ReactElement,
    ReactNode,
} from 'react';
import Loading from '../Loading';

export declare type ReactTag = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

export type ButtonColors = 'none' | 'blue' | 'light-blue' | 'gray' | 'red';

declare type ButtonProps<TTag extends ReactTag> = {
    size?: 'small' | 'normal' | 'big' | 'link';
    color?: ButtonColors;
    hoverColor?: ButtonColors;
    center?: boolean;
    block?: boolean;
    children?: ReactNode;
    leadingIcon?: ReactNode;
    trallingIcon?: ReactNode;
    disabled?: boolean;
    loading?: boolean;
    noBorder?: boolean;
    type?: string;
    onClick?: () => void;
    as?: TTag;
};

const BButton: FunctionComponent = ({
    children,
    ...props
}: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    children?: ReactNode;
}) => {
    return <button {...props}>{children}</button>;
};

const Button = <T extends ElementType>({
    as,
    color,
    hoverColor,
    children,
    disabled,
    leadingIcon,
    trallingIcon,
    size,
    loading,
    block,
    center,
    type,
    noBorder,
    ...rest
}: ButtonProps<T> & any) => {
    const As: ReactTag = as ?? BButton;

    const renderIcon = (Icon: ReactElement, className?: string) =>
        cloneElement(Icon, {
            className: `flex-0 h-5 w-5 stroke-1 ${className ?? ''}`,
            'aria-hidden': 'true',
        });

    /**
     * Render a child of the button component with the correct styles
     * @param child
     * @returns
     */
    const renderChild = (child?: ReactNode | string) => {
        // If the child is undefined or null, we don't need to render anything
        if (child === undefined || child === null) return <></>;

        // If the child is text, we need to add the correct styles to it and wrap it in a span
        if (typeof child === 'string') {
            return <span className="flex-1">{child}</span>;
        }

        // If the child is a react element, we need to add the correct styles
        return <div className="flex-1 flex flex-nowrap space-x-4">{child}</div>;
    };

    const usedColor = color ?? 'none';
    const usedHoverColor = hoverColor ?? usedColor;
    const usedSize = size ?? 'normal';

    return (
        <As
            className={classNames(
                'border cursor-pointer block font-semibold text-base leading-none flex items-center rounded-md space-x-4',
                'transition-colors',
                'transition-opacity',
                {
                    'w-full justify-center': block && center !== false,
                    'w-full justify-start text-left': block && center === false,
                    'justify-between': !block,
                },
                {
                    'opacity-100': !disabled,
                    'opacity-40': disabled,
                    'pointer-events-none': disabled,
                },
                {
                    'pl-6 pr-7 py-4': usedSize === 'big',
                    'pl-6 pr-7 py-3': usedSize === 'normal',
                    'pl-6 pr-7 py-2': usedSize === 'small',
                    'pl-2 pr-3 py-2': usedSize === 'link',
                    // 'px-1 py-2': noBorder !== undefined,
                },
                {
                    'bg-transparent border-gray-100 text-gray-800 fill-gray-800 stroke-gray-800':
                        usedColor === 'none' && noBorder === undefined,
                    'bg-transparent border-transparent text-gray-800 fill-gray-800 stroke-gray-800':
                        usedColor === 'none' && noBorder !== undefined,
                    'hover:bg-gray-100 hover:border-gray-100 hover:text-gray-800 hover:text-gray-800 hover:stroke-gray-800':
                        usedHoverColor === 'none' && noBorder === undefined,
                    'hover:text-gray-400 hover:text-gray-400 hover:stroke-gray-400':
                        usedHoverColor === 'none' && noBorder !== undefined,
                    'bg-blueTranslucent-300 border-transparent text-blue-500 fill-blue-500':
                        usedColor === 'light-blue',
                    'hover:bg-blue-500 hover:border-blue-500 hover:text-white hover:fill-white':
                        usedHoverColor === 'light-blue',
                    'bg-blue-500 border-transparent text-white fill-white stroke-white':
                        usedColor === 'blue',
                    'hover:bg-blue-300 hover:border-blue-300 hover:text-blue-500 hover:fill-blue-500 hover:stroke-blue-500':
                        usedHoverColor === 'blue',
                    'bg-gray-100 border-transparent text-gray-800 fill-white stroke-white':
                        usedColor === 'gray',
                    'hover:bg-gray-200 hover:border-transparent hover:text-gray-800 hover:fill-gray-800 hover:stroke-gray-200':
                        usedHoverColor === 'gray',
                    'bg-red-100 border-transparent text-red-800 fill-white stroke-white':
                        usedColor === 'red',
                    'hover:bg-red-200 hover:border-transparent hover:text-red-800 hover:fill-red-800 hover:stroke-red-200':
                        usedHoverColor === 'red',
                },
            )}
            disabled={disabled}
            type={type ?? 'button'}
            {...rest}
        >
            {leadingIcon !== undefined && leadingIcon !== null
                ? renderIcon(leadingIcon)
                : undefined}
            {renderChild(children)}
            {!loading && trallingIcon !== undefined && trallingIcon !== null
                ? renderIcon(trallingIcon)
                : undefined}
            {loading && <Loading className="flex-0 h-5 w-5 stroke-1" />}
        </As>
    );
};

export default Button;
