import './index.css';

import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from './redux/store';

import './i18n';
import { Amplify } from 'aws-amplify';
import { AMPLIFY_CONFIG } from './config';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ConfirmModalContextProvider from './components/ConfirmModal/ConfirmModalContextProvider';

Amplify.configure({
    Auth: {
        region: AMPLIFY_CONFIG.region,
        userPoolId: AMPLIFY_CONFIG.auth.userPoolId,
        userPoolWebClientId: AMPLIFY_CONFIG.auth.userPoolWebClientId,
        mandatorySignIn: false,
        oauth: {
            domain: AMPLIFY_CONFIG.auth.domain,
            scope: ['email', 'openid', 'profile'],
            redirectSignIn: AMPLIFY_CONFIG.auth.redirectUrl,
            redirectSignOut: AMPLIFY_CONFIG.auth.redirectUrl,
            responseType: 'code',
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

root.render(
    <Provider store={store}>
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <ConfirmModalContextProvider>
                    <App />
                </ConfirmModalContextProvider>
            </QueryClientProvider>
        </React.StrictMode>
    </Provider>,
);
