import { Navigate, RouteObject } from 'react-router';
import Page404 from './routes/404';

import loadable from '@loadable/component';

const DrawerLayout = loadable(() => import('./layout/DrawerLayout'));

const ActivationCodes = loadable(() => import('./routes/activationCodes'));
const ActivationCode = loadable(() => import('./routes/activationCodes/activationCode'));
const ActivationFileCheck = loadable(() => import('./routes/activation-file/check'));

const routes: RouteObject[] = [
    {
        path: '/',
        element: <DrawerLayout />,
        children: [
            {
                path: '/',
                element: <Navigate to="/activation-codes" />,
                index: true,
            },
            {
                path: '/activation-codes',
                element: <ActivationCodes />,
            },
            {
                path: '/activation-codes/:id',
                element: <ActivationCode />,
            },
            {
                path: '/activation-file-check',
                element: <ActivationFileCheck />,
            },
        ],
    },
    {
        path: '*',
        element: <Page404 />,
    },
    {},
];

export default routes;
